const setPositionTreeOpen = {
  data() {
    return {
      parent: null,
      child: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      setTimeout(() => {
        if (this.parent) {
          const rect = this.parent.getBoundingClientRect()
          this.child.style.position = 'fixed'
          this.child.style.maxWidth = this.treeBoxWidth || '250px'
          this.child.style.top = `${rect.top + 40}px`
          this.child.style.left = `${rect.left}px`
        }
      }, 0)
    },
    showTree() {
      this.$nextTick(() => {
        this.parent = document.querySelector('.vue-treeselect--open')
        this.child = document.querySelector('.vue-treeselect--open .vue-treeselect__menu')
      })
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
export default setPositionTreeOpen
