<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <action-btn
        :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
        :selected-number="selectedRows.length"
        :is-show-delete="checkQuyenXoa"
        @refresh-page="refreshCommon"
        @add-item="addCommon"
        @update-item="onUpdate"
        @delete-item="$refs.confirmDelete.showModal()"
      />
      <div class="d-flex align-items-end">
        <filter-icon
          size="20"
          class="cursor-pointer"
          :stroke-width="2"
          @click="showFormFilter"
        />
      </div>
    </div>
    <advanced-search
      v-show="formFilterShow"
      ref="formFilter"
      class="mt-2"
      @advanced-search="filterDanhSachHoSo"
    />
    <good-table
      ref="hoSo"
      class="mt-1"
      :class="checkOffAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="danhSachHoSo"
      :total="tongSoHoSo"
      :actions-permission="[checkQuyenSua, checkQuyenXoa]"
      @selected-item="onSelectRow"
      @column-filter="filterDanhSachHoSo"
      @update-item="onUpdate"
      @delete-item="setItemUpdate"
      @page-change="pageChange"
    >
      <template
        slot="custom-row"
        slot-scope="props"
      >
        <div
          v-if="props.props.column.field === 'tenTrangThai'"
          class="custom-column"
        >
          <div>{{ formatDateTime(props.props.row.ngayNop) }}</div>
          <div>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</div>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="props.props.row.tenLoaiTrangThai" />
          <!--eslint-enable-->
          <div
            v-if="props.props.row.thoiGianDenHanTraKQ"
            :class="props.props.row.thoiGianDenHanTraKQ.indexOf('Quá hạn') >= 0 ? 'text-red-cor' : 'text-green-cor'"
          >
            {{ props.props.row.thoiGianDenHanTraKQ }}
          </div>
        </div>
        <span v-if="props.props.column.field == 'code'">
          <a
            href="javascript:;"
            @click="detailHoSo(props.props.row)"
          >{{ `${props.props.row.code}` }}</a>
          <div>
            <cloud-icon
              size="15"
              class="custom-class"
            /> {{ props.props.row.kieuTiepNhan }}
          </div>
          <div v-if="props.props.row.dichVuChuyenPhatKetQua">
            <send-icon
              size="15"
              class="custom-class"
            /> Dịch vụ chuyển phát kết quả
          </div>
        </span>
        <div
          v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
          class="custom-column"
        >
          <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
            <div class="text-yellow-cor">
              {{ props.props.row.tenTrangThai }}
            </div>

          </div>
          <div v-else>
            <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
            <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
            <div class="text-yellow-cor">
              {{ props.props.row.tenTrangThai }}
            </div>
            <div
              v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
              :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
            >
              {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
            </div>
          </div>

        </div>
        <div
          v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'"
          class="word-break"
        >
          <div v-if="props.props.row.chuHoSo_HoVaTen">
            <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
          </div>
          <div
            v-if="props.props.row.chuHoSo_DiaChiDayDu"
            class="text-top word-break"
          >
            <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
          </div>
        </div>
        <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
          {{ props.props.row.tenThuTucHanhChinh }}
        </div>
      </template>
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'tenThuTucHanhChinh'"
          id="linhVuc"
          ref="tenThuTucHanhChinh"
          v-model="filter.dataTTHCId"
          v-format-v-select
          :default-options="danhSachThuTucHanhChinh"
          placeholder="Chọn thủ tục hành chính"
          :limit="0"
          multiple
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          loading-text="Đang tìm kiếm"
          :normalizer="normalizer"
          :match-keys="['label', 'label2']"
          class="vtreeselect-chooser vtreeselect-chooser-tt"
          :load-options="onQueryChange"
          :async="true"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
          @deselect="nextTickTreeSelect()"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName}"
            :title="node.label"
            :class="labelClassName"
          >
            {{ node.label }}
          </label>
        </treeselect>
        <treeselect
          v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
          v-model="filter.trangThaiConHanQuaHan"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="danhSachThoiHan"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn thời hạn"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
          @deselect="nextTickTreeSelect()"
        />
        <treeselect
          v-else-if="props.props.column.field === 'tenTrangThai'"
          v-model="filter.loaiTrangThaiConHanQuaHan"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="danhSachThoiHan"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn thời hạn"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
          @deselect="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete"
    />
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BCard, VBTooltip,
} from 'bootstrap-vue'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { ROUTE_NAME } from '@/modules/tiep-nhan-ho-so/constants/constants'
import GoodTable from '@/components/GoodTable.vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import END_POINTS from '@/api/endpoints'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import { formatDateTime, removeDiacritical } from '@/utils/index'
import _debounce from 'lodash/debounce'
import {
  FilterIcon,
  CloudIcon,
  SendIcon,
  UserIcon,
  MapPinIcon,
} from 'vue-feather-icons'
import setPositionTreeOpen from '@/mixins/set-position-tree-open'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    GoodTable,
    CommonModal,
    ActionBtn,
    BCard,
    CloudIcon,
    SendIcon,
    FilterIcon,
    UserIcon,
    MapPinIcon,
    AdvancedSearch,
  },
  mixins: [setPositionTreeOpen],
  data() {
    return {
      faMagnifyingGlass,
      taoHoSoRouter: ROUTE_NAME.NAME + ROUTE_NAME.HS_TRUC_TIEP.NAME + ROUTE_NAME.THEM_MOI,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '250px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'tenTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chi tiết',
          field: 'tenLoaiTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      tongSoHoSo: 0,
      danhSachHoSo: [],
      selectedRows: [],
      filter: {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      },
      formFilterShow: false,
      danhSachThuTucHanhChinh: [],
      danhSachThoiHan: [
        {
          id: 1,
          label: 'Còn hạn',
        },
        {
          id: 2,
          label: 'Quá hạn',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.HSTNTRUC_TIEP.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.HSTNTRUC_TIEP.XOA])
    },
    checkOffAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkOffAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.filterDanhSachHoSo()
    this.getDanhSachThuTucHanhChinh()
  },
  methods: {
    formatDateTime,
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.danhSachThuTucHanhChinh.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    detailHoSo(data) {
      this.$router.push({ path: '/ho-so/chi-tiet', query: { id: data.id, name: this.$route.name } })
    },
    getDanhSachThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachThuTucHanhChinh = response.data.data
        }
      })
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterDanhSachHoSo()
      })
    },
    filterDanhSachHoSo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      if (this.filter.trangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.loaiTrangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.dataTTHCId.length) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      if (param.chuHoSo_HoVaTen || this.filter.chuHoSo_HoVaTen) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          // eslint-disable-next-line no-nested-ternary
          chuHoSo: param.chuHoSo_HoVaTen ? param.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : this.filter.chuHoSo_HoVaTen ? this.filter.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.code || this.filter.code) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          // eslint-disable-next-line no-nested-ternary
          maHoSo: param.code ? param.code.replace(/\s+/g, ' ').trim() : this.filter.code ? this.filter.code.replace(/\s+/g, ' ').trim() : '',
        }
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DANH_SACH, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachHoSo = response.data.data
          this.tongSoHoSo = response.data.totalCount || 0
          this.$refs.hoSo.resetCurrentPage(payload.pageNumber)
        }
      })
    },
    pageChange() {
      const data = this.$refs.hoSo.getCurrentPage()
      this.payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DANH_SACH, this.payload, false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.danhSachHoSo = response.data.data
            this.tongSoHoSo = response.data.totalCount || 0
          }
        })
    },
    onUpdate(item) {
      this.$router.push({ path: `cap-nhat/${item.id}` })
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
      this.$refs.confirmDelete.showModal()
    },
    onDelete() {
      const payload = {
        listId: this.selectedRows.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.XOA, payload).then(res => {
        if (res.data?.succeeded) {
          if (this.danhSachHoSo.length === 1) {
            const currentPage = this.$refs.hoSo.getCurrentPage()
            const index = currentPage.pageNumber - 1
            this.$refs.hoSo.resetCurrentPage(index === 0 ? 1 : index)
          }
          this.filterDanhSachHoSo()
        }
      })
    },
    addCommon() {
      this.$router.push(ROUTE_NAME.THEM_MOI)
    },
    resetFilter() {
      this.filter = {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: null,
        maHoSo: null,
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    refreshCommon() {
      if (this.$refs.formFilter) {
        this.$refs.formFilter.resetForm()
      }
      this.$refs.hoSo.$refs.vbTables.reset()
      this.resetFilter()
      this.filterDanhSachHoSo()
    },
    clearFilter(field) {
      const found = this.columns.find(c => c.field === field)
      found.filterOptions.filterValue = ''
    },
    showFormFilter() {
      this.formFilterShow = !this.formFilterShow
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.hoSo.onColumnFilter()
      })
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name || node.label,
        label2: removeDiacritical(node.name || node.label),
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.input-icon {
  padding: 8px 0 8px 8px;
  border: 1px solid #d8d6de;
  border-right: none;
}

.input-group-icon {
  border-left: none;
}

.router-link-color {
  a {
    color: white;
  }
}

.custom-column {
  p {
    margin-bottom: 0;
  }
}
.text-top{
    margin-top:6px;
  }
</style>
